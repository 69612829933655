/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Sofia"), React.createElement("p", null, "Pour se loger, Sofia s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/bg/sofia/citycenter.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre de Sofia"), " : autour du boulevard de Vitocha, l’artère commerçante de la capitale bulgare."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/bg/sofia/oborishte.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Oborishte"), ", à l’est : le quartier de la cathédrale Alexandre-Nevski et de nombreuses institutions culturelles."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/bg/sofia/lozenets.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Lozenets"), ", au sud : un quartier résidentiel avec de nombreux parcs et le zoo de Sofia notamment."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/bg/sofia/vitosha.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vitocha"), ", au sud de Lozenets : un quartier très verdoyant avec de magnifiques maisons, près de l’église de Boyana et au pied du mont Vitocha."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/bg/sofia/studentskigrad.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Studentski grad"), ", au sud-est : le quartier étudiant, avec des immeubles de la période communiste et une vie nocturne très animée."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
